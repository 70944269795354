// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-asset-management-js": () => import("./../../../src/pages/asset-management.js" /* webpackChunkName: "component---src-pages-asset-management-js" */),
  "component---src-pages-azitek-successfully-reaches-a-total-of-1-m-euros-in-investment-js": () => import("./../../../src/pages/azitek-successfully-reaches-a-total-of-1m-euros-in-investment.js" /* webpackChunkName: "component---src-pages-azitek-successfully-reaches-a-total-of-1-m-euros-in-investment-js" */),
  "component---src-pages-how-we-accidentally-became-tugger-train-efficiency-experts-js": () => import("./../../../src/pages/how-we-accidentally-became-tugger-train-efficiency-experts.js" /* webpackChunkName: "component---src-pages-how-we-accidentally-became-tugger-train-efficiency-experts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meeting-market-demands-aziteks-recognition-in-the-indoor-location-market-js": () => import("./../../../src/pages/meeting-market-demands-aziteks-recognition-in-the-indoor-location-market.js" /* webpackChunkName: "component---src-pages-meeting-market-demands-aziteks-recognition-in-the-indoor-location-market-js" */),
  "component---src-pages-milk-run-js": () => import("./../../../src/pages/milk-run.js" /* webpackChunkName: "component---src-pages-milk-run-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-production-asset-tracking-js": () => import("./../../../src/pages/production-asset-tracking.js" /* webpackChunkName: "component---src-pages-production-asset-tracking-js" */),
  "component---src-pages-the-challenge-of-monitoring-internal-manufacturing-processes-js": () => import("./../../../src/pages/the-challenge-of-monitoring-internal-manufacturing-processes.js" /* webpackChunkName: "component---src-pages-the-challenge-of-monitoring-internal-manufacturing-processes-js" */),
  "component---src-pages-the-impact-of-tracking-returnable-transport-items-in-modern-supply-chains-js": () => import("./../../../src/pages/the-impact-of-tracking-returnable-transport-items-in-modern-supply-chains.js" /* webpackChunkName: "component---src-pages-the-impact-of-tracking-returnable-transport-items-in-modern-supply-chains-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */)
}

